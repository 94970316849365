import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import * as bootstrap from "bootstrap/dist/js/bootstrap.esm";
import "@/assets/styles/app.scss";

import { SnackbarService, Vue3Snackbar } from "vue3-snackbar";
import "vue3-snackbar/dist/style.css";

import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import Loading from "@/views/components/Loading.vue";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App).use(router).use(pinia).use(SnackbarService);
app.component("loading", Loading);
app.component("vue3-snackbar", Vue3Snackbar);
app.mount("#app");
