import { defineStore } from "pinia";
import { api } from "@/services/api";
import router from "@/router";
import { auth } from "@/services/firebase";
import {
  signInWithEmailAndPassword,
  signOut,
  OAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  GoogleAuthProvider,
} from "firebase/auth";

export const useStore = defineStore({
  id: "main-store",
  persist: true,
  state: () => ({
    user: null,
    token: null,
  }),
  actions: {
    async requestEmailLink(email) {
      return await api.post("/request-email-link", {
        email,
        requester: "employee-portal",
      });
    },
    async verifyEmailLink(token) {
      const { data } = await api.post("/verify-email-link", { token: token });
      if (data.success) {
        this.user = data.user;
        router.push("/dashboard");
      } else {
        this.user = null;
        router.push("/login");
      }
    },

    async emailPasswordLogin(payload) {
      signInWithEmailAndPassword(auth, payload.email, payload.password)
        .then(async (userCredential) => {
          // Signed in
          const user = userCredential.user;
          console.log("user", user);
          this.token = await user.getIdToken();
          await this.refreshUser();
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
        });
    },

    async checkMicrosoftRedirectResult() {
      getRedirectResult(auth)
        .then(async (result) => {
          console.log(result);
          // User is signed in.
          // IdP data available in result.additionalUserInfo.profile.

          // Get the OAuth access token and ID Token
          const credential = OAuthProvider.credentialFromResult(result);
          const accessToken = credential.accessToken;
          const idToken = credential.idToken;
          console.log(credential);

          this.token = await auth.currentUser.getIdToken();
          this.refreshUser();
        })
        .catch((error) => {
          // Handle error.
          console.log(error);
        });
    },

    async checkGoogleRedirectResult() {
      getRedirectResult(auth)
        .then(async (result) => {
          // This gives you a Google Access Token. You can use it to access Google APIs.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;

          // The signed-in user info.
          const user = result.user;

          this.token = await user.getIdToken();
          await this.refreshUser();
        })
        .catch((error) => {
          console.log(error.code);
          // Handle Errors here.
          // const errorCode = error.code;
          // const errorMessage = error.message;
          // The email of the user's account used.
          // const email = error.customData.email;
          // The AuthCredential type that was used.
          // const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
        });
    },

    async microsoftLogin() {
      const provider = new OAuthProvider("microsoft.com");
      signInWithRedirect(auth, provider);
    },

    async googleLogin() {
      const provider = new GoogleAuthProvider();
      signInWithRedirect(auth, provider);
    },

    async refreshUser() {
      if (this.token) {
        const { data } = await api.get("/me");
        this.user = data;
        router.push("/dashboard");
      }
    },

    signOut() {
      signOut(auth)
        .then(() => {
          this.$patch({
            user: null,
            token: null,
          });

          router.push("/");
        })
        .catch((error) => {
          // An error happened.
          console.log("signout error", error);
          this.$patch({
            user: null,
          });

          router.push("/");
        });
    },
  },
});
