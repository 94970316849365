import { createRouter, createWebHistory } from "vue-router";
import { useStore } from "@/store";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home-page" */ "../views/pages/Home.vue"),
  },

  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "login-page" */ "../views/auth/Login.vue"),
  },

  {
    path: "/packages",
    name: "packages",
    component: () => import(/* webpackChunkName: "packages-page" */ "../views/areas/packages/Index.vue"),
  },

  {
    path: "/links",
    name: "links",
    component: () => import(/* webpackChunkName: "links-page" */ "../views/areas/links/Index.vue"),
  },

  {
    path: "/verify-login/:token",
    name: "verify-login",
    component: () => import(/* webpackChunkName: "verify-login-page" */ "../views/auth/VerifyLogin.vue"),
  },

  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import(/* webpackChunkName: "areas-dashboard-index" */ "../views/areas/dashboard/Index.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/profile",
    name: "profile",
    component: () => import(/* webpackChunkName: "profile-page" */ "../views/areas/profile/Index.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/addresses",
    name: "addresses",
    component: () => import(/* webpackChunkName: "addresses-page" */ "../views/areas/addresses/Index.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/employees",
    name: "employeesindex",
    component: () => import(/* webpackChunkName: "employees-index" */ "../views/areas/employees/Index.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/employees/:id",
    name: "employeesview",
    component: () => import(/* webpackChunkName: "employees-view" */ "../views/areas/employees/View.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/employees/create",
    name: "employeescreate",
    component: () => import(/* webpackChunkName: "employees-create" */ "../views/areas/employees/Create.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/support-tickets/create",
    name: "support-tickets-create",
    component: () =>
      import(/* webpackChunkName: "support-tickets-create" */ "../views/areas/support-tickets/Create.vue"),
    meta: {
      auth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const store = useStore();
  const loggedInUser = store.user;

  if (to.matched.some((record) => record.meta.auth) && !loggedInUser) {
    next("/login");
    return;
  }

  if (to.path === "/login" || to.path === "/") {
    if (loggedInUser) {
      next("/dashboard");
    }
  }

  next();
});

export default router;
